import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {container} from 'tsyringe';
import {store} from '@/store';
import {locale} from '@/assets/db/db.conf.ts';
import {
    SearchPhoneNumberServiceSandbox
} from '@/pages/search-phone-number-service/sandbox/search-phone-number-service.sandbox';
import SearchInfoResultComponent
    from '@/pages/search-phone-number-service/components/search-info-result/search-info-result.component.vue';
import {Login} from '@/core/configs/api';

@Options({
    name: 'search-info-number-component',
    components: {
        SearchInfoResultComponent,
    },
    props: {
        cities: {
            type: Array,
            required: true
        }
    }

})

export default class SearchInfoNumberComponent extends Vue {
    public sandbox: SearchPhoneNumberServiceSandbox = container.resolve(SearchPhoneNumberServiceSandbox);
    public store = store;
    public locale$: any = locale;
    public locale: any = null;
    public localeMessage = null;
    public mask = '+994(##)-###-##-##';
    public prefix = '';
    public phone = '+994(';
    public error = {
        prefix: false,
        phone: false,
    };

    get getInformation() {
        return this.store.getters.getInformation;
    }


    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.searchPhoneNumber) {
                this.locale = res.searchPhoneNumber;
                this.localeMessage = res.message;
            }
        });
    }

    public selectCity(info: any) {
        this.phone = '';
        this.prefix = info;
        this.phone = `+994(${info})`;
        this.error.prefix = false;
        this.selectMaskInput();
        this.store.commit('SET_SEARCH_NUMBER_RESET');
    }

    public selectMaskInput() {
        if (String(this.prefix).length === 4) {
            this.mask = '+994(####)-##-##-#';
        } else {
            this.mask = '+994(##)-###-##-##';
        }
    }

    public validationPrefix() {
        if (!this.prefix) {
            this.phone = '+994(';
            this.error.prefix = true;
        }
    }

    public validation() {
        let numberPhone = this.phone.replace(/[^0-9]+/g, '').slice(3 + String(this.prefix).length);
        if (String(this.prefix).length === 2) {
            if (numberPhone.length === 7) {
                this.error.phone = false;
            } else {
                this.error.phone = true;
            }
        } else if (String(this.prefix).length === 4) {
            if (numberPhone.length === 5) {
                this.error.phone = false;
            } else {
                this.error.phone = true;
            }
        }
    }

    public deleteNumber(event: any) {
        if ((event.keyCode === 8) || (event.keyCode === 46)) {
            if (String(this.prefix).length === 2) {
                if (this.phone.length <= 8) {
                    this.phone = `+994(${this.prefix})-`
                }

            } else if (String(this.prefix).length === 4) {
                if (this.phone.length <= 12) {
                    this.phone = `+994(${this.prefix})-`
                }
            }
        }
    }

    public sendData() {
        this.validation();
        if (!this.error.phone) {
            let numberPhone = this.phone.replace(/[^0-9]+/g, '').slice(3 + String(this.prefix).length);
            let payload = {
                prefix: this.prefix,
                number: numberPhone,
            };
            this.sandbox.getSearchNumber(payload);
        }
    }


}
