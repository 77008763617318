import 'reflect-metadata';
import {Options, Vue} from 'vue-class-component';
import {container} from 'tsyringe';
import {store} from '@/store';
import {locale} from '@/assets/db/db.conf.ts';
import {
    SearchPhoneNumberServiceSandbox
} from '@/pages/search-phone-number-service/sandbox/search-phone-number-service.sandbox';

@Options({
    name: 'search-info-result-component',

})

export default class SearchInfoResultComponent extends Vue {
    public sandbox: SearchPhoneNumberServiceSandbox = container.resolve(SearchPhoneNumberServiceSandbox);
    public store = store;
    public locale$: any = locale;
    public locale: any = null;
    public localeMessage = null;


    public created(): void {
        this.locale$.subscribe((res: any) => {
            if (res && res.searchPhoneNumber) {
                this.locale = res.searchPhoneNumber;
                this.localeMessage = res.message;
            }
        });
    }

    public selectCity(info:any) {

    }


}
