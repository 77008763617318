<style src="./search-phone-number-service.component.scss" lang="scss"></style>

<template>
  <div class="search-phone-number">
    <section class="mt-20">
      <div class="container-fluid v-container">
        <div class="row">
          <div class="col-12">
            <section class="mt-20">
              <service-name-component
                  :serviceName="commonService.name"
                  :organization="commonService.structureName"
                  :img="commonService.file"
                  :payment="commonService.paymentRequired"
                  :registered="commonService.authRequired"
                  :serviceId="commonService.id"
                  :structureId="commonService.structureId"
                  :headers="commonService.serviceHeader"
              />
            </section>
            <section>
              <customer-info-component :user="userData"/>
            </section>
            <section class="mt-30 mb-100">
<!--                <services-subtitle-component>-->
<!--                  {{ locale.header }}-->
<!--                </services-subtitle-component>-->
                <search-info-number-component :cities="cities"/>
<!--                <not-data-component class="mt-20" v-if="(mobileNumbersList.length === 0) && userData.name">-->
<!--                  {{localeMessage.notData}}-->
<!--                </not-data-component>-->
            </section>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script src="./search-phone-number-service.component.ts"></script>
