import 'reflect-metadata';
import {mixins, Options, Vue} from 'vue-class-component';
import {container} from 'tsyringe';
import {store} from '@/store';
import {locale} from '@/assets/db/db.conf.ts';
import CustomerInfoComponent from '@/core/shared/customer-info/customer-info.component.vue';
import ServiceNameComponent from '@/core/shared/service-name/service-name.component.vue';
import {
    SearchPhoneNumberServiceSandbox
} from '@/pages/search-phone-number-service/sandbox/search-phone-number-service.sandbox';
import {IServiceCommon} from '@/types/service-common';
import NotDataComponent from '@/core/shared/not-data/not-data.component.vue';
import ServiceSubtitleComponent from '@/core/shared/service-subtitle/service-subtitle.component.vue';
import SearchInfoNumberComponent
    from '@/pages/search-phone-number-service/components/search-info-number/search-info-number.component.vue';
import {IServiceHeader} from '@/types/service-header';
import {ISearchSelect} from '@/core/layouts/public/types/search-select-structure';
import {Router} from '@/core/mixin/router';

@Options({
    name: 'search-phone-number-service-component',
    components: {
        CustomerInfoComponent,
        ServiceNameComponent,
        NotDataComponent,
        ServiceSubtitleComponent,
        SearchInfoNumberComponent
    }

})

export default class SearchPhoneNumberServiceComponent extends  mixins(Router) {
    public service = 1093;
    public sandbox: SearchPhoneNumberServiceSandbox = container.resolve(SearchPhoneNumberServiceSandbox);
    public store = store;
    public locale$: any = locale;
    public locale: any = null;
    public localeMessage = null;


    get userData() {
        return store.getters._getUserFullData;
    }

    get commonService(): IServiceCommon {
        return this.store.getters._getCommonService;
    }

    get serviceHeader(): IServiceHeader {
        return this.store.getters._getCommonServiceHeader;
    }

    get cities(): ISearchSelect[] {
        return this.store.getters.getCities;
    }

    public created(): void {
        this.store.commit('SET_SEARCH_NUMBER_RESET')
        this.locale$.subscribe((res: any) => {
            if (res && res.searchPhoneNumber) {
                this.locale = res.searchPhoneNumber;
                this.localeMessage = res.message;
            }
        });
        this.sandbox.getCities();
    }
    public mounted() {
        this.getServiceData();
    }


}
