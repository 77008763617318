import 'reflect-metadata';
import {injectable} from 'tsyringe';
import {store} from '@/store';


@injectable()
export class SearchPhoneNumberServiceSandbox {
    private store = store;

    constructor() {
    }

    getCommonService(payload: number): any {
        return this.store.dispatch('getServiceCommon', payload);
    }

    getCommonServiceHeader(payload: number): any {
        return this.store.dispatch('getServiceHeaderCommon', payload);
    }



    getCities(): any {
        return this.store.dispatch('getCities');
    }

    getSearchNumber(payload: any): any {
        return this.store.dispatch('getSearchNumber', payload);
    }
}
