<style src="./search-info-number.component.scss" lang="scss"></style>

<template>
  <div class="search-info-number">
    <v-card class="h-container">

      <form class="form" @submit.prevent="sendData">
        <div class="form__group ">
          <label class="v-label">{{ locale.city }}</label>
          <v-select
              :error="error.prefix"
              @select="selectCity"
              :placeholder="locale.city"
              :options="cities"/>
        </div>
        <div class="form__group mt-20">
          <label class="v-label">{{ locale.phone }}</label>
          <div class="form__wrapper d-md-flex">
            <input
                @keyup="deleteNumber($event)"
                @keydown="deleteNumber($event)"
                :class="{'v-input--error':error.phone}"
                @input="validationPrefix"
                placeholder="+994("
                v-mask="[`${mask}`]"
                v-model="phone"
                type="text" class="v-input mr-20" id="tel">
            <div class="col-12 p-0 d-lg-none d-block">
              <v-divider class=" my-30"/>
            </div>
            <v-btn color="dark">{{ locale.search }}</v-btn>
          </div>

        </div>
      </form>
      <div class="search-info-number-details d-lg-block d-none" v-if="getInformation?.phone">
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-3 col-12">
                <div class="search-info-number-details__group">
                  <div class="search-info-number-details-descr">
                    Telefon:
                  </div>
                  <div class="search-info-number-details-mean">
                    {{ getInformation.phone }}
                  </div>
                </div>
                <div class="search-info-number-details__group">
                  <div class="search-info-number-details-descr">
                    Soyadı, adı və atasının adı
                  </div>
                  <div class="search-info-number-details-mean">
                    {{ getInformation.name }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="search-info-number-details__group">
                  <div class="search-info-number-details-descr">
                    Qeydiyyatda olduğu ünvan
                  </div>
                  <div class="search-info-number-details-mean">
                    {{ getInformation.street }} {{ getInformation.address }}
                  </div>
                </div>
                <div class="search-info-number-details__group">
                  <div class="search-info-number-details-descr">
                    Abunəçinin növü
                  </div>
                  <div class="search-info-number-details-mean">
                    {{ getInformation.phoneType }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12" v-if="getInformation.phoneNumberDebt">
            <div class="search-info-number-details-debs__group" v-for="item in getInformation.phoneNumberDebt">
              <div class="search-info-number-details-debs__descr">
                {{ item.description }}
              </div>
              <div class="search-info-number-details-debs__mean"
                   :class="{
              'search-info-number-details-debs__mean--red':Math.sign(item.debt)===-1,
              'search-info-number-details-debs__mean--green':Math.sign(item.debt)===1
            }">
                {{ item.debt }} AZN
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <v-card class="mt-20 d-lg-none d-block" v-if="getInformation?.phone">
      <div class="search-info-number-details d-lg-none d-block m-0" >
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-3 col-12">
                <div class="search-info-number-details__group">
                  <div class="search-info-number-details-descr">
                    Telefon:
                  </div>
                  <div class="search-info-number-details-mean">
                    {{ getInformation.phone }}
                  </div>
                </div>
                <div class="search-info-number-details__group">
                  <div class="search-info-number-details-descr">
                    Soyadı, adı və atasının adı
                  </div>
                  <div class="search-info-number-details-mean">
                    {{ getInformation.name }}
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="search-info-number-details__group">
                  <div class="search-info-number-details-descr">
                    Qeydiyyatda olduğu ünvan
                  </div>
                  <div class="search-info-number-details-mean">
                    {{ getInformation.street }} {{ getInformation.address }}
                  </div>
                </div>
                <div class="search-info-number-details__group">
                  <div class="search-info-number-details-descr">
                    Abunəçinin növü
                  </div>
                  <div class="search-info-number-details-mean">
                    {{ getInformation.phoneType }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12" v-if="getInformation.phoneNumberDebt">
            <div class="search-info-number-details-debs__group" v-for="item in getInformation.phoneNumberDebt">
              <div class="search-info-number-details-debs__descr">
                {{ item.description }}
              </div>
              <div class="search-info-number-details-debs__mean"
                   :class="{
              'search-info-number-details-debs__mean--red':Math.sign(item.debt)===-1,
              'search-info-number-details-debs__mean--green':Math.sign(item.debt)===1
            }">
                {{ item.debt }} AZN
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script src="./search-info-number.component.ts"></script>
